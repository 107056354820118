import { commonApi } from "@/api/common.js";
export default {
  components: {},
  data: () => ({
    userName: "",
    fullName: "",
    passWord: "",
    isLoadingUpdateProfile: false,
  }),
  created() {
    this.getDetailProfile();
  },
  methods: {
    async getDetailProfile() {
      await commonApi
        .getDetailProfile(JSON.parse(localStorage.getItem("userData")).id)
        .then((res) => {
          this.userName = res.slug;
          this.fullName = res.name;
        });
    },
    async updateProfile() {
      this.isLoadingUpdateProfile = true;
      let body = {
        id: JSON.parse(localStorage.getItem("userData")).id,
        name: this.fullName,
        password: this.passWord,
      };
      await commonApi
        .updateProfile(JSON.parse(localStorage.getItem("userData")).id, body)
        .then(() => {
          this.getDetailProfile();
        });
      this.isLoadingUpdateProfile = false;
    },
  },
};
